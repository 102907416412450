import React from 'react'
import {Box, Card, Container, css, Flex} from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import ListItem from '@solid-ui-components/ListItem'
import FlexOverlapFade from '@solid-ui-components/FlexOverlapFade'
import ContentText from '@solid-ui-components/ContentText'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import ContentButtons from '@solid-ui-components/ContentButtons'
import Divider from "@solid-ui-components/Divider";

const styles = {
    cards: {
        height: ['275px', '375px'],
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexShrink: 1,
        padding: '1.5rem',
        paddingTop: '2rem',
        gap: '2rem'
    }
}

const FeaturesWithPhotoBlock02 = ({
                                      content: {text, collection, buttons},
                                      reverse
                                  }) => (
    <Container sx={{position: `relative`}}>
        <Flex
            sx={{
                alignItems: ['center', `center`],
                flexDirection: [
                    reverse ? `row-reverse` : `row`,
                    reverse ? `column-reverse` : `column`
                ],
                flexWrap: 'wrap',
                mx: [null, null, null, -4]
            }}
        >
            <Box
                sx={{
                    textAlign: ['center'],
                    flexBasis: [`1`, `1/2`, `1/2`],
                    mx: 4
                }}>
                <ContentText content={text}/>
            </Box>

            <Divider space={3}/>

            <Flex sx={{width: '100%'}} reverse={reverse}>

                {collection && (
                    <>
                        <Flex sx={{flexWrap: `wrap`, alignItems: 'center', flex: 1}}>
                            {collection.map((props, index) => (
                                <Reveal
                                    key={`item-${index}`}
                                    effect='fadeInPop'
                                    delay={0.2 * (index + 1)}
                                    css={css({flexBasis: [`1`, `1/4`, `1/4`]})}
                                >
                                    <Card py='1' m='2' sx={styles.cards}>
                                        <ListItem {...props} vertical center gap={'1.5rem'} iconProps={{size: ''}}/>
                                    </Card>
                                </Reveal>
                            ))}
                        </Flex>
                    </>
                )}
            </Flex>
        </Flex>
        <FlexOverlapFade direction={reverse ? 'ltr' : 'rtl'}/>
        {buttons && (
            <>
                <Divider space={3}/>
                <ContentButtons content={buttons}/>
            </>
        )}
    </Container>
)

export default WithDefaultContent(FeaturesWithPhotoBlock02)
